/*  --------------------------------------------------------------------------------
  --- FONTS
  -------------------------------------------------------------------------------- */

/* UNUSED */

/*  --------------------------------------------------------------------------------
--- RESET
-------------------------------------------------------------------------------- */

/*
http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	margin: 0;
	padding: 0;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	text-decoration: none;
}

/*  --------------------------------------------------------------------------------
--- VAR
-------------------------------------------------------------------------------- */
:root {
	/* Font */
	--default-font-family: "Helvetica", sans-serif;

	/* Widths */
	--width-content-min: 1080px;
	--width-content-max: 1600px;
	--width-modal: 500px;
	--width-side-panel-regular: 400px;
	--width-side-panel-big: 800px;
	--width-logout-section-min: 430px;

	/* Heights */
	--height-header: 64px;
	--height-content-padding: 32px;
	--height-content-start: calc(
		var(--height-header) + var(--height-content-padding)
	);

	/* Z-indexes */
	--z-index-tooltip-checkbox: 1;
	--z-index-content: 300;
	--z-index-header: 450;
	--z-index-side-panel: 500;
	--z-index-modal: 600;
	--z-index-toast: 700;
	--z-index-floating-menu: 900;
	--z-index-scroll-hider: 1000;

	/* Toastify specifics */
	--toastify-color-progress-success: var(--joy-palette-green-3);
	--toastify-color-progress-error: var(--joy-palette-red-1);
	--toastify-color-progress-info: var(--joy-palette-blue-primary);
}

/*  --------------------------------------------------------------------------------
--- GLOBAL
-------------------------------------------------------------------------------- */
html {
	height: 100%;
	font-weight: 400;
	font-size: 16px;
	font-feature-settings: "lnum";
	box-sizing: border-box;
	color: var(--joy-palette-grey-text);
	background: var(--joy-palette-grey-1);
}

*,
*::before,
*::after {
	box-sizing: inherit;
}
